import React from "react";
import { FcClock } from "react-icons/fc";


const BuyNowSection: React.FC<{}> = () => {
    return (
        <section>
            <div className="flex">
            <div className="col-6 bg-red-500 text-white">
                <div className="text-7xl float-end px-3 py-4"><FcClock className="inline-block"/> 24 <span className="text-3xl">hrs everyday</span></div>
            </div>
            <div className="col-6 bg-blue-500 text-white">
                <div className="py-5">
                    <a className="btn !rounded-4xl !bg-red-500 mx-4" href={ `https://wa.me/254701974004?text=Hello%Rafiki.%20I%20a%20small%20problem%20and%20I%20would%20like%you%20to%20help` } target="_blank" rel="noreferrer">
                        Order via whatsapp
                    </a>
                </div>
            </div>
            </div>
        </section>
    )

}

export default React.memo(BuyNowSection);