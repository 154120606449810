import React from "react";
import { MDBCarousel, MDBCarouselItem, MDBCarouselCaption } from 'mdb-react-ui-kit';
import DrugBg from '../../../assets/img/carousel/backgrounds/drug.jpg';
import OrderBg from '../../../assets/img/carousel/backgrounds/order.jpg';
import OrderImg from '../../../assets/img/carousel/content/order.png';
import DrugImg from '../../../assets/img/carousel/content/drug.png';
import Available from '../../../assets/img/carousel/content/available.png';
import { RiMedicineBottleLine } from "react-icons/ri";
import { MdDeliveryDining } from "react-icons/md";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";



const Carousel: React.FC <{}> = () => {
    return (
        <>
            <MDBCarousel showControls>
                <MDBCarouselItem itemId={1}>
                    <img src={DrugBg} className='d-block w-100' alt='...' />
                    <div className="container relative">
                        <MDBCarouselCaption>
                            <div className="flex relative">
                                <div className="col-7"><img src={DrugImg} alt=""/></div>
                                    <div className="text-left right-content col-5 pt-4">
                                        <h1 className="capitalize text-6xl leading-2">Your medicare companion</h1>
                                        <div className="mt-5">
                                            <span className="iconated-slider mr-6"><span className="flex"><RiMedicineBottleLine size={40} color="#24aeb1" className="mr-2 icon flex-col"/><span className="flex-col leading-5">Temperature<br/>controlled Meds</span></span></span>
                                            <span className="iconated-slider mr-6"><span className="flex"><MdDeliveryDining size={40} color="#24aeb1" className="mr-2 icon flex-col"/><span className="flex-col leading-5">Doorstep<br/>Delivery</span></span></span>
                                            <span className="iconated-slider"><span className="flex"><FaHandHoldingDollar size={40} color="#24aeb1" className="mr-2 icon flex-col"/><span className="flex-col leading-5">Up to<br/>25% off</span></span></span>
                                        </div>
                                    <div className="mt-5 pt-4">
                                        <Link to={"/shop"} className="mt-3 rounded-3xl py-3 px-4 bg-white !text-gray-700 font-medium uppercase">Shop Now <FaLongArrowAltRight className="inline-block mx-2"/></Link>
                                    </div>
                                </div>
                            </div>
                        </MDBCarouselCaption>
                    </div>
                </MDBCarouselItem>

                <MDBCarouselItem itemId={2}>
                    <img src={OrderBg} className='d-block w-100' alt='...' />
                    <div className="container relative">
                        <MDBCarouselCaption>
                            <div className="flex relative">
                                    <div className="text-left right-content col-6 pt-4">
                                        <div className="mt-3 pt-5">
                                            <h3>Products</h3>
                                            <h1 className="capitalize text-6xl leading-2 Capitalize">Flat 25% Off<br/>Medicine order</h1>
                                            <div className="mt-3 Capitalize font-medium">
                                                Original Price Ksh. 999.00
                                            </div>
                                            <div className="mt-5">
                                                <Link to={"/shop"} className="mt-3 rounded-3xl py-3 px-4 bg-white !text-gray-700 font-medium uppercase">Shop Now <FaLongArrowAltRight className="inline-block mx-2"/></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6"><img className="float-end" src={OrderImg} alt=""/></div>
                            </div>
                        </MDBCarouselCaption>
                        
                    </div>

                </MDBCarouselItem>
                <MDBCarouselItem itemId={3}>
                    <img src={OrderBg} className='d-block w-100' alt='...' />
                    <div className="container relative">
                        <MDBCarouselCaption>
                            <div className="flex relative">
                                    <div className="text-left right-content col-6 pt-4">
                                        <div className="mt-3 pt-5">
                                            <h3 className="mb-2">Available</h3>
                                            <div className="mt-2">
                                                <span className="!mt-3 capitalize text-9xl bg-red-500 rounded-full leading-2 Capitalize carousel-cta">24</span>
                                            </div>
                                            <div className="mt-3 Capitalize font-medium">
                                                <span className="bg-blue-700 px-4 py-2">Hours Everyday</span>
                                            </div>
                                            <div className="mt-5">
                                                <Link to={"/shop"} className="mt-3 rounded-3xl py-3 px-4 bg-white !text-gray-700 font-medium uppercase">Shop Now <FaLongArrowAltRight className="inline-block mx-2"/></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6"><img className="float-end" src={Available} alt=""/></div>
                            </div>
                        </MDBCarouselCaption>
                        
                    </div>
                </MDBCarouselItem>
            </MDBCarousel>
        </>
    )
}

export default React.memo(Carousel);