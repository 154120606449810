import React from "react";
// import { MDBCheckbox } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { MDBListGroup, MDBListGroupItem } from 'mdb-react-ui-kit';
import { BiClinic } from "react-icons/bi";
import { TbDental } from "react-icons/tb";
import { GrAttraction } from "react-icons/gr";
import LabImage from '../../../assets/svg/laboratory.svg';
import PerfumeImage from '../../../assets/svg/perfumery.svg';
import AltMedImage from '../../../assets/svg/alternative-medicine.svg';
import WellnessImage from '../../../assets/svg/wellness.svg';
import PharmacyImage from '../../../assets/svg/pharmay.svg';
import ClinicImage from '../../../assets/svg/clinic.svg';
import DentalImage from '../../../assets/svg/dental.svg';
import BeautyImage from '../../../assets/svg/beauty.svg';



const AboutUsContent: React.FC<{}> = () => {

    return (
        <>
            <div className="container my-4">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="my-3 text-red-500 mb-2">Background</h4>
                        <p>Rafiki Pharmaceuticals LTD is one of the key players in pushing for easy Access of the healthcare to the people. On this course, Rafiki is involved in offering 24/7 access to pharmacy services</p>
                        
                        <h4 className="my-3 text-red-500 mb-2">Mission</h4>
                        <p>To provide pharmacy services that meet utmost international standards and satisfy our clients’ needs
through integrity, professionalism, reliability, convenience and accountability.</p>
                        
                        <div className="my-2"></div>
                        <h4 className="my-3 text-red-500 mb-2">Vision</h4>
                        <p>To be the leading pharmacy services provider in East, Central and Southern Africa region.</p>

                    </div>
                    <div className="col-md-6">
                    <h4 className="mb-3 text-red-500 my-3">Services</h4>
                        <div className="">
                        <MDBListGroup style={{ minWidth: '22rem' }} light small>
                            <MDBListGroupItem>
                                <Link to={"/services/pharmacy"}>
                                    <img src={PharmacyImage} alt="lab" className="inline-block mr-2" width={25}/> Pharmacy
                                </Link>
                            </MDBListGroupItem>
                            <MDBListGroupItem>
                                <Link to={"/services/healthcare"}>
                                    <img src={ClinicImage} alt="lab" className="inline-block mr-2" width={25}/> Healthcare Clinics
                                </Link>
                            </MDBListGroupItem>
                            <MDBListGroupItem>
                                <Link to={"/services/dental"}>
                                    <img src={DentalImage} alt="lab" className="inline-block mr-2" width={25}/> Dental Services
                                </Link>
                            </MDBListGroupItem>
                            <MDBListGroupItem>
                                <Link to={"/services/beauty"}>
                                    <img src={BeautyImage} alt="lab" className="inline-block mr-2" width={25}/> Beauty Products
                                </Link>
                            </MDBListGroupItem>
                            <MDBListGroupItem>
                                <Link to={"/services/laboraty"}>
                                    <img src={LabImage} alt="lab" className="inline-block mr-2" width={25}/>Laboratory
                                </Link>
                                </MDBListGroupItem>
                            <MDBListGroupItem>
                                <Link to={"/services/perfumery"}>
                                    <img src={PerfumeImage} alt="lab" className="inline-block mr-2" width={25}/> Perfumery
                                </Link>
                            </MDBListGroupItem>
                            <MDBListGroupItem>
                                <Link to={"/services/alternative-medicine"}>
                                    <img src={AltMedImage} alt="lab" className="inline-block mr-2" width={25}/> Alternative Medicine
                                </Link>
                            </MDBListGroupItem>
                            <MDBListGroupItem>
                                <Link to={"/services/wellness"}>
                                    <img src={WellnessImage} alt="lab" className="inline-block mr-2" width={25}/> Wellness
                                </Link>
                            </MDBListGroupItem>
                        </MDBListGroup>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        
                    </div>
                </div>
            </div>
            <section className="text-white primary-bg-color py-7">
                <div className="container py-7">
                    <div className="row">
                        <div className="col-12 capitalize my-3 border-b border-gray-100 mb-5"><h1>Why Choose us</h1></div>
                        <div className="col-md-6">
                            <h3 className="my-2">Integrity</h3>
                            <p>We endeavour to serve our clients with the highest ethical standards at all times.</p>
                            <h3 className="my-2">Innovation</h3>
                            <p className="mb-3">We strive to constantly innovate in order to augment our services for cost effectiveness, and convenience of our clients.</p>
                            <h3 className="my-2">Customer Focus</h3>
                            <p>Our dynamic policies, procedures and processes are geared towards optimum clients’ satisfaction</p>
                        </div>
                        <div className="col-md-6">
                            <h3 className="my-2">Teamwork</h3>
                            <p className="mb-3">Our cohesion is our strength and pillar that ensures seamless delivery of our renown premier customer centric pharmacy services.</p>
                            <h3 className="my-2">Professionalism</h3>
                            <p className="mb-3">We value our employees as a critical corporate asset. We invest in their Continuous professional development for the benefit of our esteemed clients.</p>
                            <h3 className="my-2">Respect</h3>
                            <p className="mb-3">We care enough and think about how we impact others-At the core of our operation is respectful and caring staff</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default React.memo(AboutUsContent);