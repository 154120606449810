import React from "react";
import IntroImage from "../../../assets/img/content/intro.png"
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa6";
const Services: React.FC<{}> = () => {

    return (
        <>
            <section className="intro-block" id="intro">
                <div className="container mb-6">


                    {/* Accordion Services */}
                    
                    <MDBAccordion flush>
                        <MDBAccordionItem collapseId={1} headerTitle='Pharmacy'>
                            <p>Rafiki Pharmacy offers quite a variety of pharmaceutical products including medicine</p>
                            <Link to={'/shop?filter=pharmacy'} className="font-bold !text-blue-500">See Products</Link>
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={2} headerTitle='Healthcare Clinics'>
                            <p>We offer Healthcare clinics***</p>

                            <Link to={'/services/healthcare-clinics'} className="font-bold !text-blue-500">See healthcare Services</Link>
                            <p>To book  or to get more information on skincare consultation walk to any of our branches or reach us through 0701974004</p>
                            <p>Or book via whatsapp</p>
                            <a className='btn btn-primary left-3 bg-green-500 text-white p-1 px-3 rounded-3xl text-xl' href={ `https://wa.me/254701974004?text=Hello%Rafiki.%20I%20a%20small%20problem%20and%20I%20would%20like%you%20to%20help` } target="_blank" rel="noreferrer">
                                        <FaWhatsapp size={25} className='mr-3 inline-block' />
                                        chat Now
                                    </a>
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={3} headerTitle='Dental Services'>
                            <p>We offer dental services**</p>
                            <p>To book  or to get more information on skincare consultation walk to any of our branches or reach us through 0701974004</p>
                            <p>Or book via whatsapp</p>
                            <a className='btn btn-primary left-3 bg-green-500 text-white p-1 px-3 rounded-3xl text-xl' href={ `https://wa.me/254701974004?text=Hello%Rafiki.%20I%20a%20small%20problem%20and%20I%20would%20like%you%20to%20help` } target="_blank" rel="noreferrer">
                                        <FaWhatsapp size={25} className='mr-3 inline-block' />
                                        chat Now
                                    </a>
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={4} headerTitle='Beauty Products'>
                            <p>We provide a range of skin care products ranging from moisturizers, toners, masks and scrubs, to ensure radiant and healthy skin throughout all the seasons of life! Our skin specialists offer free skin care consultations from concerns such as eczema, Acne-prone skin, to managing how your skin is making you feel, our advisers help you determine the products best suited for your skin type.</p>

                            <p>Our range of skin care products covers not just dermatological treatments but a whole selection of cosmetics and beauty treatments</p>

                            <p>To book  or to get more information on skincare consultation walk to any of our branches or reach us through 0701974004</p>

                            <Link to={'/shop?filters=beauty'} className="!text-blue-500 font-bold">Get Products</Link>
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={5} headerTitle='Laboratory'>
                            <p>We provide an enhanced patient experience through a one-stop-shop for diagnostic and pharmaceutical services. You can now access laboratory tests and pharmaceutical services under one roof, this is in support of evidence based medicine that aids best clinical practices</p>
                            <p>Our phlebotomists are well-trained and equipped to handle sample collection with utmost care, ensuring accurate test results.</p>
                            <p>We understand that personalized care is crucial in the healthcare industry. That’s why we have made it our priority to offer our clients the best possible care by providing tailored services to meet their unique needs.</p>
                            <Link to={'/services/laboratory'}  className="font-bold !text-blue-500">More</Link>
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={6} headerTitle='Perfumery'>
                            <p>To book  or to get more information on skincare consultation walk to any of our branches or reach us through 0701974004</p>

                            <Link to={'/shop?filters=perfumery'} className="!text-blue-500 font-bold">Get Products</Link>
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={7} headerTitle='Alternative Medicine'>
                            <p>To book  or to get more information on skincare consultation walk to any of our branches or reach us through 0701974004</p>

                            <Link to={'/shop?filters=alternaitive-medicine'} className="!text-blue-500 font-bold">Get Products</Link>
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={8} headerTitle='Wellness'>
                            <p>To book  or to get more information on skincare consultation walk to any of our branches or reach us through 0701974004</p>

                            <Link to={'/shop?filters=wellness'} className="!text-blue-500 font-bold">Get Products</Link>
                        </MDBAccordionItem>
                    </MDBAccordion>
                </div>
            </section>
            <section>

            </section>
        </>
    )
}

export default React.memo(Services);