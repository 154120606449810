import React from "react";
import type ShopItem from "../utils/interfaces";

const FeaturedProducts1: React.FC<{}> = () => {

    const items: ShopItem[] = [
        {
            name: "axiom karela jamun juice",
            category: "medicine",
            image: 'medicine/featured1.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200,
        },
        {
            name: "face mask",
            category: "medicine",
            image: 'medicine/features2.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200,
            trending: true
        },
        {
            name: "face Washing",
            category: "medicine",
            image: 'medicine/features3.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200,
        }

    ]

    return (
        <section className="featured-items">
            <div className="container">
                <div className="row">
                    <div className="w-full text-gray-700 capitalize text-2xl mb-3 font-medium">Trending</div>
                        { items?.map((item:ShopItem, index: number) => (
                            <div id="featured-item1" className="col-md-4 uppercase font-medium">
                                <div className={`featured-item flex rounded-md  ${item?.trending ? 'trending': ''}`}>
                                    <div id="info-block" className="w-1/2 mr-5 overflow-hidden">
                                        <div className="mb-2">Beauty Pack</div>
                                        <div className="text-2xl whitespace-nowrap">{item?.name}</div>
                                        <div className="text-2xl">up to {Math.ceil(((item?.mrp - item?.currentPrice) / item?.mrp) * 100)}%</div>
                                        <button className="mt-4 uppercase featured-purchase">Shop Now</button>
                                    </div>
                                    <div className="w-1/2 pl-4" id="image-block">
                                        <img src={require(`../../assets/img/products/${item?.image}`)} alt="clever"/>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </section>
    )
}

export default React.memo(FeaturedProducts1)