import React from "react";
import PageHeader from "../utils/page-header";
import AboutUsContent from "../sections/about/about"
const About: React.FC<{}> = () => {


    return (
        <>
            <PageHeader title="About Us" />
            <AboutUsContent />
            
        </>
    )
}

export default React.memo(About);