import React from "react";
import EmptyRecords from "../../utils/empty-records";

const Index: React.FC<{}> = () => {
    return (
        <>
            <div className="my-4 container"><EmptyRecords itemname="partners" /></div>
        </>
    )
}


export default React.memo(Index);