import React from "react";
import type ShopItemInterface from "../../utils/interfaces";
import { FaRegStar, FaPlus  } from "react-icons/fa";

interface ShopItemProps {
    item: ShopItemInterface
}
const ShopItem: React.FC<ShopItemProps> = ({item}: ShopItemProps) => {

    return (
        <div id="" className=" text-gray-400 mb-5">
            <div id="item-thumb" className="mb-4">
                <img className="w-full" src={require(`../../../assets/img/products/${item?.image}`)} alt={item?.name} />
            </div>
            <div id="sub-category" className="font-bold text-gray-400 mb-2 uppercase">{item?.subCategory}</div>
            <div id="name" className="capitalize mb-3 text-gray-700" style={{fontWeight:"600"}}>{item?.name}</div>
            <div id="rating" className="font-medium text-gray-400 mb-4">
                <span className="primary-bg-color rounded-md px-2 py-1 text-white mr-3">{item?.stars} <FaRegStar className="inline-block leading-1" size={15} color="white"/></span><span>{item?.ratings} Ratings</span>
            </div>
            <div id="slicing" className="uppercase font-medium mb-2">
                <span id="old-price" className="">Mrp <span className="line-through mr-4">{item?.mrp}</span></span>
                <span className="primary-text-color"> {Math.ceil(((item?.mrp - item?.currentPrice) / item?.mrp) * 100) }% off</span>
            </div>
            <div id="price-purchase" className="font-medium mb-3 relative">
                <span className="text-gray-700">Ksh. {item?.currentPrice}</span>
                {/* We'll add a purchase button component here */}
                <span className="font-bold text-right float-end primary-text-color mr-5">Add <FaPlus className="inline-block" size={15}/></span>
            </div>
        </div>
    )
}

export default ShopItem