import React from "react";
import PageHeader from "../utils/page-header";
import CareersIndex from "../sections/careers/index";
const Careers: React.FC<{}> = () => {

    return (
        <>
            <PageHeader title="Vacancies" />
            <CareersIndex />
        </>
    )
}

export default React.memo(Careers);