import React, {useState} from "react";
import Logo from '../../assets/img/logo.png';
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBCollapse,
    MDBInputGroup
  } from 'mdb-react-ui-kit';

import { CiMail, CiUser } from "react-icons/ci";
import { FaPhoneAlt, FaSearch  } from "react-icons/fa";
import { Link } from "react-router-dom";
import { LuShoppingBag } from "react-icons/lu";
import { BsShop } from "react-icons/bs";



const Header: React.FC<{}> = () => {
    const [openBasic, setOpenBasic] = useState(false);

  return (
    <header className="border-b border-gray-100">
        <section className="top-nav bg-blue-500 text-white">
                <MDBNavbar expand='lg' light bgColor={undefined}>
                <MDBContainer>

                <MDBNavbarNav className='w-auto mr-auto mb-2 mb-lg-0'>
                    <MDBNavbarItem>
                        <MDBNavbarLink className="">
                            Free Delivery <CiMail className="inline-block mr-1" size={20}/> info@rafikipharmaceuticals.com <span className="border-r border-gray-300 px-1 "></span>
                        </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                        <MDBNavbarLink>
                            <FaPhoneAlt className="inline-block mr-1" size={20}/> +254701974004/ 254728691199
                        </MDBNavbarLink>
                    </MDBNavbarItem>
                    
                </MDBNavbarNav>

                <div className='d-flex input-group w-auto'>
                    <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 uppercase'>
                        <MDBNavbarItem>
                            <MDBNavbarLink>
                                <Link to={"/partners"}>Partners</Link><span className="border-r border-gray-300 px-1 "></span>
                            </MDBNavbarLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <MDBNavbarLink>
                                <Link to={"/services"}>Services</Link><span className="border-r border-gray-300 px-1 "></span>
                            </MDBNavbarLink>
                        </MDBNavbarItem>
                        {/* <MDBNavbarItem>
                            <MDBNavbarLink>
                                <Link to={"/order-now"}>order Now</Link><span className="border-r border-gray-300 px-1 "></span>
                            </MDBNavbarLink>
                        </MDBNavbarItem> */}

                        <MDBNavbarItem>
                            <MDBNavbarLink>
                                <Link to={"/contact-us"}>Contact Us</Link><span className="border-r border-gray-300 px-1 "></span>
                            </MDBNavbarLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <MDBNavbarLink>
                                <Link to={"/track"}>Track</Link>
                            </MDBNavbarLink>
                        </MDBNavbarItem>
                        
                    </MDBNavbarNav>
                </div>
            </MDBContainer>
            </MDBNavbar>
        </section>

        <section className="main-header py-3">
            <div className="container">
                <nav className="bg-white border-gray-200 dark:bg-gray-900">
                    <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto">
                        <a href="/" className="flex">
                            <img src={Logo} className="pr-2" style={{maxWidth:"50px"}} alt="Flowbite Logo" />
                            <span className="self-center text-2xl whitespace-nowrap dark:text-white">
                                <div className="font-bold text-5xl title-font text-blue-500">Rafiki</div>
                                <div className="-mt-1 border-b-8 text-blue-500 tracking-tight border-red-500 title-font !font-light text-xl">Pharmaceuticals Ltd</div>
                            </span>
                        </a>
                        <div className="bg-primary-light plain-button rounded-3xl px-2 py-1">
                            <MDBInputGroup className='header-search' textAfter={<FaSearch className="" />}>
                                <MDBDropdown>
                                <MDBDropdownToggle className="plain-btn">All Category</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link>Pharmacy</MDBDropdownItem>
                                    <MDBDropdownItem link>Healthcare Clinics</MDBDropdownItem>
                                    <MDBDropdownItem link>Dental Services</MDBDropdownItem>
                                    <MDBDropdownItem link>Beauty Products</MDBDropdownItem>
                                    <MDBDropdownItem link>Laboratory</MDBDropdownItem>
                                    <MDBDropdownItem link>Perfumery</MDBDropdownItem>
                                    <MDBDropdownItem link>Alternative Medicine</MDBDropdownItem>
                                    <MDBDropdownItem link>Wellness</MDBDropdownItem>
                                </MDBDropdownMenu>
                                </MDBDropdown>
                                <span className="border-r border-gray-300 my-2"></span>
                                <input className='form-control border-none bg-transparent font-bold' type='text' placeholder="Search..."/>
                            </MDBInputGroup>
                            
                        </div>
                        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                        <ul className="font-medium flex flex-col md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white">
                            <li>
                                <Link to="/shop" className="block uppercase" aria-current="page"><BsShop size={30} className="inline-block mr-1" />Shop</Link>
                            </li>
                            <li>
                                <a href="#" className="block uppercase" aria-current="page"><CiUser size={30} className="inline-block mr-1" /> Sign in / Sign Up</a>
                            </li>
                            <li>
                                <a href="#" className="relative" aria-current="page"><LuShoppingBag size={30} className="inline-block"/><span className="text-white text-center bg-red-500 rounded-full absolute mx-auto font-bold" style={{zIndex:"9999", minWidth:"19px", height:"15px", top:"-3px", fontSize:"10px", display:"block", right:"0"}}>0</span></a> <span className="ml-3">0.00</span>
                            </li>
                            
                        </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </section>
      
    </header>
  );
};

export default Header;