import React from "react";
import Home from "./pages/home";


const Index: React.FC<{}> = () => {
  return (
    <Home />
  );
};

export default React.memo(Index);