import React from "react";
import Slider from "../sections/home/slider";
import FeaturedProducts from "../sections/home/featured-products";
import FeaturedProducts1 from "../sections/featured-products-1";
import BuyNowSection from "../sections/buy-now";
const Home: React.FC <{}> = () => {

    return (
        <>
            <Slider />
            <BuyNowSection />
            <FeaturedProducts />
            <section><FeaturedProducts1 /></section>
        </>
    )
}

export default React.memo(Home);