import React from "react";
import PageHeader from "../utils/page-header";
import ServicesSection from "../sections/services/services";

const Services: React.FC<{}> = () => {

    return (
        <>
            <PageHeader title="Services" />
            <ServicesSection />
        </>
    )
}

export default React.memo(Services);