import React from "react";
const PageHeader = (props) => {
    const {title} = props;

    return (
        <section className="page-header border-b border-gray-300 capitalize">
            <div className="container py-2 text-gra-700">
                <h1 className="">{title}</h1>
            </div>
        </section>
    )
}

export default React.memo(PageHeader)