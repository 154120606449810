import React from "react";
import PageHeader from "../utils/page-header";
import PartnersIndex from "../sections/partners/index";

const Partners: React.FC<{}> = () => {

    return (
        <>
            <PageHeader title="Partners: Insurance" />
            <PartnersIndex />

        </>
    )
}

export default React.memo(Partners);