import React from "react";
import { FaPhoneAlt, FaWhatsapp, FaDoorOpen } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBTextArea,
    MDBFile
  }
  from 'mdb-react-ui-kit';

const Index: React.FC<{}> = () => {

    return (
        <>
            <section className="py-7">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h5 className="my-3 text-blue-500">Main Phone Numbers</h5>
                            <p className="font-medium text-gray-500">
                                <FaPhoneAlt className="inline-block mr-1" size={20}/> +254701974004
                            </p>
                            <p className="font-medium text-gray-500">
                                <FaPhoneAlt className="inline-block mr-1" size={20}/> +254728691199
                            </p>
                            <h5 className="my-3 mt-4 text-blue-500">
                                Company Email
                            </h5>
                            <p className="font-medium text-gray-500">
                              <CiMail className="inline-block mr-1" size={20}/> info@rafikipharmaceuticals.com
                              <br/>
                              <CiMail className="inline-block mr-1" size={20}/> admin@rafikipharmaceuticals.com
                            </p>

                            <div className="my-5 mb-1">
                                <h5 className="my-2 text-blue-500">Order via Whatsapp Number</h5>
                                <p className="font-medium">
                                    <a className='btn btn-primary left-3 bg-green-500 text-white p-1 px-3 rounded-3xl text-xl' href={ `https://wa.me/254701974004?text=Hello%Rafiki.%20I%20a%20small%20problem%20and%20I%20would%20like%you%20to%20help` } target="_blank" rel="noreferrer">
                                        <FaWhatsapp size={25} className='mr-3 inline-block' />
                                        chat Now
                                    </a>
                                </p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <MDBCard>
                                <MDBCardBody className='px-4'>
                                    <h5 className="text-blue-500 my-3">
                                        Send Message
                                    </h5>
                                    <div>
                                        <MDBRow className='align-items-center pt-4 pb-3'>
                                            <MDBCol md={'12'} className='mb-2'><h6 className="mb-0">Email or Mobile number</h6></MDBCol>
                                            <MDBCol md='12' className=''>
                                                <MDBInput label='example@example.com/0712345678' size='lg' id='form2' type='email'/>
                                            </MDBCol>

                                            <MDBCol md={'12'} className='-5 mt-4 mb-2'><h6 className="mb-0">Enter Message</h6></MDBCol>
                                            <MDBCol md='12' className=''>
                                                <MDBTextArea label='Message' id='textAreaExample' rows={3} />
                                            </MDBCol>
                                            <MDBCol md='12' className=''>
                                                <MDBBtn className='my-4 ' size='lg'>send Message</MDBBtn>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                        
                    </div>
                    <div className="row">
                    <div className="mt-2 col-md-6">
                            <h4 className="text-blue-500 my-3">
                                Branches
                            </h4>
                            <div className="text-gray-500">
                                <h5 className="text-red-500">Nairobi</h5>
                                <h6 className="text-blue-500 my-2">Samad Building</h6>
                                <div className="mb-4">
                                    <p className="">Samad Building, 12th Street, Eastleigh Section2, second Avenue, Nairobi</p>
                                    <span className="font-bold my-"><FaDoorOpen className="inline-block mr-1"/> Opening Hours </span>
                                    <br/>
                                    <span className="">
                                    <MDBRow className='align-items-center'>
                                        <MDBCol sm={'6'}>Mon - Friday: </MDBCol>
                                        <MDBCol sm={'6'}>6.00 am to 6.00PM </MDBCol>
                                    </MDBRow>
                                    </span>                                    Sat
                                </div>

                                <h6 className="text-blue-500 my-2">Rafiki Building</h6>
                                <div className="mb-4">
                                    <p className="">Rafiki Building, 9th Street, Eastleigh Section2, second Avenue, Nairobi</p>
                                    <span className="font-bold my-"><FaDoorOpen className="inline-block mr-1"/> Opening Hours </span>
                                    <br/>
                                    <span className="">
                                    <MDBRow className='align-items-center'>
                                        <MDBCol sm={'6'}>Mon - Friday: </MDBCol>
                                        <MDBCol sm={'6'}>6.00 am to 6.00PM </MDBCol>
                                    </MDBRow>
                                    </span>                                    Sat
                                </div>


                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
        </>
    )
}


export default React.memo(Index);