import React, {useEffect, useState} from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import EmptyRecords from "../../utils/empty-records";
import type ShopItemInterface from "../../utils/interfaces";
import ShopItem from "../shop-item/shop-item";

const FeaturedProducts: React.FC <{}> = () => {
    const [key, setKey] = useState<string>("medicines");
    const [items, setItems] = useState<ShopItemInterface[]>([]);
    
    

    const Products: ShopItemInterface[] = [
        {
            name: "axiom karela jamun juice",
            category: "care",
            image: 'care/karela.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "care",
            image: 'care/episoft.png',
            subCategory: 'sanitizers',
            stars: 4.5,
            ratings: 150,
            mrp: 260,
            currentPrice: 170

        },
        {
            name: "axiom karela jamun juice",
            category: "care",
            image: 'care/shield.png',
            subCategory: 'creame',
            stars: 3.7,
            ratings: 400,
            mrp: 500,
            currentPrice: 490

        },
        {
            name: "axiom karela jamun juice",
            category: "care",
            image: 'care/protein.png',
            subCategory: 'juice',
            stars: 4.8,
            ratings: 174,
            mrp: 100,
            currentPrice: 79

        },
        {
            name: "axiom karela jamun juice",
            category: "care",
            image: 'care/karela.png',
            subCategory: 'cream',
            stars: 4.3,
            ratings: 260,
            mrp: 490,
            currentPrice: 470

        },
        {
            name: "axiom karela jamun juice",
            category: "care",
            image: 'care/protein.png',
            subCategory: 'juice',
            stars: 4.6,
            ratings: 263,
            mrp: 490,
            currentPrice: 470

        },
        {
            name: "axiom karela jamun juice",
            category: "medicine",
            image: 'medicine/1.png',
            subCategory: 'juice',
            stars: 3.8,
            ratings: 201,
            mrp: 700,
            currentPrice: 650

        },
        {
            name: "axiom karela jamun juice",
            category: "medicine",
            image: 'medicine/2.png',
            subCategory: 'juice',
            stars: 3.9,
            ratings: 231,
            mrp: 321,
            currentPrice: 300

        },
        {
            name: "axiom karela jamun juice",
            category: "medicine",
            image: 'medicine/karela.png',
            subCategory: 'juice',
            stars: 4.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "medicine",
            image: 'medicine/2.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "medicine",
            image: 'medicine/3.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "medicine",
            image: 'medicine/karela.jpg',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "medicine",
            image: 'medicine/karela.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "medicine",
            image: 'medicine/karela.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "medicine",
            image: 'medicine/karela.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "medicine",
            image: 'medicine/karela.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "medicine",
            image: 'medicine/karela.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "medicine",
            image: 'medicine/karela.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "devices",
            image: 'medicine/karela.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "devices",
            image: 'medicine/karela.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "devices",
            image: 'medicine/karela.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "devices",
            image: 'devices/digital.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "devices",
            image: 'medicine/karela.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "devices",
            image: 'devices/mask.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "devices",
            image: 'devices/1.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "devices",
            image: 'devices/digital.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
        {
            name: "axiom karela jamun juice",
            category: "devices",
            image: 'devices/karela.png',
            subCategory: 'juice',
            stars: 3.5,
            ratings: 200,
            mrp: 300,
            currentPrice: 200

        },
    ]

    // function to change items
    function getItems(key: string): void {
        const newProducts: ShopItemInterface[] = Products.filter((item) => item?.category.includes(key));
        setItems(newProducts);
    };
    useEffect(()=>{
        setKey("medicine");
    }, []);

    useEffect(()=>{
        getItems(key);
    }, [key])

    return (
        <section className="py-3 my-5">
            <div className="container">
                <h5 className="uppercase text-gray-400 text-sm">
                    Our shop
                </h5>
                <h3 className="uppercase text-2xl font-medium text-gray-700">Popular Categories</h3>
                <Tabs
                id="products-selector"
                activeKey={key}
                onSelect={(k) => setKey(k != null ? k : "")}
                className="products-selector mb-3"
                title = "my title"
                >
                    <Tab eventKey="medicine" title="Medicines">
                        <div className="row">
                            {items?.slice(0, 4).map((x:ShopItemInterface, index:number) => (
                                <div className="col-md-3">
                                    <ShopItem item={x}/>
                                </div>
                            ))}
                        </div>
                        {items?.length === 0 ? <EmptyRecords itemname={key}/> : ""}
                    </Tab>
                    <Tab eventKey="care" title="Skin Care">
                        <div className="row">
                            {items.slice(0, 4).map((x:ShopItemInterface, index:number) => (
                                <div className="col-md-3">
                                    <ShopItem item={x}/>
                                </div>
                            ))}
                        </div>
                        {items?.length === 0 ? <EmptyRecords itemname={key}/> : ""}
                    </Tab>
                    <Tab eventKey="devices" title="Devices">
                        <div className="row">
                            {items.slice(0, 4).map((x:ShopItemInterface, index:number) => (
                                <div className="col-md-3">
                                    <ShopItem item={x}/>
                                </div>
                            ))}
                        </div>
                        {items?.length === 0 ? <EmptyRecords itemname={key}/> : ""}
                    </Tab>
                    
                </Tabs>
                <div className="border-t border-gray-200 mt-2 pt-2">Go to Shop</div>
            </div>
        </section>
    )
}

export default React.memo(FeaturedProducts);