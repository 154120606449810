import React from "react";
import Carousel from "./carousel";
const Slider: React.FC <{}> = () => {

    return (
        <section>
            <Carousel />
        </section>
    )
}

export default React.memo(Slider);