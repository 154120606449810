import React from "react";
import PageHeader from "../utils/page-header";
import ContactsIndex from "../sections/contacts/index";




const Contacts: React.FC<{}> = () => {


    return (
        <>
            <PageHeader title="Contact Us" />
            <ContactsIndex />
        </>
    )
}


export default React.memo(Contacts);