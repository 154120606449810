import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
// import "@fortawesome/fontawesome-free/css/all.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import Index  from './components/index';
import {render} from "react-dom";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useLocation
} from 'react-router-dom'

import Header from './components/sections/header';
import Footer from './components/sections/footer';
import Store from './context/store';
import About from './components/pages/about';
import Services from './components/pages/services';
import Partners from './components/pages/partners';
import Contacts from './components/pages/contact-us';
import Careers from './components/pages/careers';
import ProtectedRoute from './components/utils/protected-route';
import Track from './components/pages/track';
import Terms from './components/pages/terms';
import Login from './components/pages/login';



const App = () => {

  // const [state, ] = useContext(Context);
  // const isAuthenticated = getFromLocalStorage("user");
  const location = useLocation();
  // const pathname = location;

  return (
              <>
              
              <div className="">
             

              <div className="">
                  <div className="flex flex-col min-h-screen">
                    <main className='flex-1 border-b border-gray-100 mb-7'>
                      {location.pathname.includes("login") ? "" : <Header />}
                      <div className=''>
                        <Routes>
                        <Route path="/" element={<Index/>}/>
                        <Route path="/about-us" element={<About />}/>
                        <Route path="/services" element={<Services />}/>
                        <Route path="/partners" element={<Partners />}/>
                        <Route path="/contact-us" element={<Contacts />}/>
                        <Route path="/vacancies" element={<Careers />}/>
                        <Route path="/terms" element={<Terms />}/>
                        {/* <Route path="/login" element={<Login />}/> */}

                            {/* <Route exact path="/login" element={<Login/>}/> */}
                            {/* <Route exact path="/reset-password" element={<ResetPassword/>}/> */}
                            {/* <Route exact path="/logout" element={<Logout/>}/> */}
                            <Route element={<ProtectedRoute /> }>
                                <Route path="/track" element={<Track />}/>
                            </Route>
                            <Route path="*" element={<Index />}/>
                        </Routes>
                      </div>
                    </main>  
                    {location.pathname.includes("login") ? "" : <Footer />}
                      {/* {isAuthenticated !=null ? <Footer />: ""} */}
                  </div>
                  
              </div>
              
            </div>
            
            </>
  )
}

const container = document.getElementById("app");

render((
  <Store>
      <BrowserRouter>
          <App />
      </BrowserRouter>
  </Store>
), container);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
