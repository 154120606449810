import React from "react";
import PageHeader from "../utils/page-header";
import TermsAndPolicy from "../sections/terms-and-policy";

const Terms: React.FC <{}> = () => {

    return (
        <>
            <PageHeader title="Terms and policy" />
            <TermsAndPolicy />
        </>
    )
}

export default React.memo(Terms);