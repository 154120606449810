import React from "react";
import { TbTruckDelivery } from "react-icons/tb";
import { LiaHandHoldingUsdSolid } from "react-icons/lia";
import { RiCustomerService2Line } from "react-icons/ri";
import { IoBagCheckOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import {
  MDBBtn, MDBInputGroup 
} from 'mdb-react-ui-kit';
import { FaRocket } from "react-icons/fa";
import Android from "../../assets/img/content/download_btn01.png";
import Apple from "../../assets/img/content/download_btn02.png";
import { FaFacebook, FaInstagram, FaYoutube  } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";



const Footer: React.FC<{}> = () => {
  return (
    <footer className="pt-4">
      <section className=""  id="summary-block">
        <div className="container">
            <div className="row border-b border-gray-300 pb-3 mb-4">
                <div className="col-md-3 pb-3 md:!pl-0">
                  <div className="flex">
                    <div className="mr-3 mt-2 primary-text-color opacity-70"><LiaHandHoldingUsdSolid size={40} /></div>
                    <div>
                      <div className="capitalize font-medium">Free Easy returns</div>
                      <div className="text-gray-400">Return to 7 days</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 pb-3">
                  <div className="flex">
                    <div className="mr-3 mt-2 primary-text-color opacity-70"><TbTruckDelivery size={40} /></div>
                    <div>
                      <div className="capitalize font-medium">Free Delivery</div>
                      <div className="text-gray-400">orders over KSH. 4000</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 pb-3">
                  <div className="flex">
                    <div className="mr-3 mt-2 primary-text-color opacity-70"><RiCustomerService2Line size={40} /></div>
                    <div>
                      <div className="capitalize font-medium">All Day support</div>
                      <div className="text-gray-400">24/7 customer care</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 pb-3">
                  <div className="flex">
                    <div className="mr-3 mt-2 primary-text-color opacity-70"><IoBagCheckOutline size={40} /></div>
                    <div>
                      <div className="capitalize font-medium">Secure Checkout</div>
                      <div className="text-gray-400">100% protected by payouts</div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
      </section>

      <section className="my-3" id="siteMap">
        <div className="container mt-5">
          <div className="row my-4">
            <div className="col-md-3 mt-2">
              {/* <h4 className="text-2xl capitalize">Quick Links</h4> */}
              <h5 className="capitalize">Quick Links</h5>
              <ul className="pl-0 mt-3 capitalize footer-links">
                <li><Link to={"/about-us"}>About Us</Link></li>
                <li><Link to={"/contact-us"}>Contact us</Link></li>
                <li><Link to={"/about-us"}>Press Coverage</Link></li>
                <li><Link to={"/partners"}>Partners</Link></li>
                <li><Link to={"/vacancies"}>Vacancies</Link></li>
                <li><Link to={"/faq"}>FAQ</Link></li>
                <li><Link to={"/terms"}>Terms and policy</Link></li>
              </ul>
            </div>
            <div className="col-md-3 mt-2">
              <h5 className="capitalize">Benefits</h5>
              <ul className="pl-0 mt-3 capitalize footer-links">
                <li><Link to={"/benefits/free-delivery"}>Free Delivery</Link></li>
                <li><Link to={"/benefits/support"}>Support</Link></li>
                <li><Link to={"/benefits/payment"}>Convenient Payments</Link></li>
                <li><Link to={"/benefits/free-returns"}>Free Returns</Link></li>
                {/* <li><Link to={"/about-us"}>Fitness</Link></li> */}
              </ul>
            </div>
            <div className="col-md-3 mt-2">
              <h5 className="capitalize">Services</h5>
              <ul className="pl-0 mt-3 capitalize footer-links">
                <li><Link to={"/services/pharmacy"}>Pharmacy</Link></li>
                <li><Link to={"/services/healthcare"}>Healthcare Clinics</Link></li>
                <li><Link to={"/services/dental"}>Dental Services</Link></li>
                <li><Link to={"/services/beauty"}>Beauty Products</Link></li>
                <li><Link to={"/services/laboraty"}>Laboratory</Link></li>
                <li><Link to={"/services/perfumery"}>Perfumery</Link></li>
                <li><Link to={"/services/alternative-medicine"}>Alternative Medicine</Link></li>
                <li><Link to={"/services/wellness"}>Wellness</Link></li>
              </ul>
            </div>
            <div className="col-md-3 mt-2">
              <h5 className="capitalize">Subscribe to Newsletter</h5>
              <div className="mt-3 text-gray-400">
                <p>Get a free subscription to our pharmacy</p>
                <MDBInputGroup className='mb-3'> 
                    <input className='form-control bg-primary-light font-bold py-2' type='text' placeholder="Enter email address"/>
                    <MDBBtn className="" style={{background:"#24aeb1", border:"1px solid #24aeb1"}}><FaRocket className="text-white"/></MDBBtn> 

                </MDBInputGroup>

                
              </div>
              <div className="mt-4">
                  <h5 className="py-2">Download App</h5>
                  <img src={Android} className="md:mr-3 inline-block" alt="android_app" />
                  <img src={Apple} className="inline-block" alt="iphone_app" />
                </div>
            </div>
          </div>
        </div>
      </section>

      <section className="border-t border-gray-200">
        <div className="container py-6">
          <span className="py-2 mr-3">copyright&copy; 2022 Rafiki Pharmacy. All Rights reserved</span>
          <span className="py-2 float-end">
            <FaFacebook className="inline-block mr-2" size={25}/>
            <FaInstagram className="inline-block mr-2" size={25}/>
            <BsTwitterX className="inline-block mr-2" size={25}/>
            <FaYoutube className="inline-block mr-2" size={25}/>
          </span>
        </div>
      </section>
    </footer>
  );
};

export default Footer;