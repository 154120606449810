import React from "react";
import EmptyRecords from "../utils/empty-records";

const TermsAndPolicy: React.FC <{}> = () => {

    return (
        <section className="my-2 py-7">
            <div className="container">
                <EmptyRecords itemname="terms and policy" />
            </div>
        </section>
    )
}

export default React.memo(TermsAndPolicy);