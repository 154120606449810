import React from "react";
import EmptyRecords from "../../utils/empty-records";

const Index: React.FC<{}> = () => {

    return (
        <section className="py-7">
            <div className="container">
                <EmptyRecords itemname="Orders" />
            </div>
        </section>
    )
}

export default React.memo(Index);