import React from "react";
import PageHeader from "../utils/page-header";
import TrackingIndex from "../sections/track/index";

const Track: React.FC<{}> = () => {

    return (
        <>
            <PageHeader title="tracking" />
            <TrackingIndex />
        </>
    )
}

export default React.memo(Track)