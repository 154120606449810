

const EmptyRecords = (props) => {
    const {itemname} = props;
    return (
        <>
            <div className="w-full"><div className="p-3 bg-gray-200 text-700 w-full">No {itemname} found</div></div>
        </>
    )
}

export default EmptyRecords;